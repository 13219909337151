body {
  background-color: #eaeaea;
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg, rgb(24, 42, 115) 0%, rgb(33, 138, 174) 69%, rgb(32, 167, 172) 89%
  );
}

.ui.main.container {
  margin-top: 7em;
}

.mastHead{
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg, rgb(24, 42, 115) 0%, rgb(33, 138, 174) 69%, rgb(32, 167, 172) 89%
  ) !important;
  height: 100vh;
}

.mastHead h1.ui.header{
  font-size: 4em;
}